import { Injectable } from '@angular/core';
import {
  Actions,
  createEffect,
  ofType,
  ROOT_EFFECTS_INIT,
} from '@ngrx/effects';
import {
  authActions,
  AuthService,
  AuthState,
  AuthFacade,
  INITIAL_STATE,
} from '@ea/auth';
import { catchError, defer, map, of, switchMap, tap } from 'rxjs';
import { Router } from '@angular/router';
import { IUser } from '@ea/models';
import {
  IntercomService,
  LocalStorageService,
  ToastService,
} from '@ea/services';
import { CartFacade } from '@ea/cart';

@Injectable()
export class AuthEffects {
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      switchMap(() =>
        defer(() => {
          const userData: IUser = this.storageService.getItem('user');

          const state: AuthState = {
            ...INITIAL_STATE,
            isAuthenticated: !!userData?.bearerToken,
            isLoaded: true,
            user: {
              ...userData,
            },
          };

          const result = userData
            ? authActions.set(state)
            : authActions.set(INITIAL_STATE);

          return of(result);
        }),
      ),
    ),
  );

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.login),
      switchMap(({ payload, redirectRoute }) =>
        this.authService.login(payload).pipe(
          tap((user) => {
            this.loginUser(user);
          }),
          map((user) => authActions.loginSuccess({ user, redirectRoute })),
          catchError((error) => of(authActions.loginFail({ error }))),
        ),
      ),
    ),
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.logout),
      switchMap((data) => {
        this.storageService.removeItem('user');
        this.router.navigate(['/' + data?.redirectTo]);
        return of(authActions.set(INITIAL_STATE));
      }),
    ),
  );

  loginSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.loginSuccess),
        tap(({ user, redirectRoute }) => {
          this.storageService.setItem('user', user);
          this.toasterService.show(
            `Welcome back ${user.firstName} You have successfully logged in!`,
            'success',
          );
          if (redirectRoute) {
            this.router.navigate([redirectRoute]);
          } else {
            this.router.navigate(['account']);
          }
        }),
      ),
    { dispatch: false },
  );

  loginFailToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.loginFail),
        tap((res) => {
          if (res?.error?.message !== 'redirect') {
            this.toasterService.show(
              "Sorry, we couldn't log you in. Please double-check your username and password and try again.",
              'error',
            );
          }
        }),
      ),
    { dispatch: false },
  );

  register$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.register),
      switchMap(({ payload }) =>
        this.authService.register(payload).pipe(
          map(({ response: success, uuid }) =>
            success
              ? authActions.registerSuccess({ uuid })
              : authActions.registerFail({
                  error: new Error('Registration failed'),
                }),
          ),
          catchError((error) => of(authActions.registerFail({ error }))),
        ),
      ),
    ),
  );

  updateProfile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.updateProfile),
        switchMap(({ payload }) =>
          this.authService.updateProfile(payload).pipe(
            tap(() => {
              this.toasterService.showToAccount(
                'Account successfully Updated!',
                'success',
              );
              this.router.navigate(['account']);
            }),
            catchError(() => {
              this.toasterService.showToAccount(
                'Failed to update Profile',
                'error',
              );
              return of();
            }),
          ),
        ),
      ),
    { dispatch: false },
  );

  registerSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.registerSuccess),
        tap(() => {
          // this.router.navigate(['ThankYou']);
        }),
      ),
    { dispatch: false },
  );

  registerFailToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.registerFail),
        tap(() => {
          this.toasterService.show(
            'Sorry, We were unable to register your account.',
            'success',
          );
        }),
      ),
    { dispatch: false },
  );

  registerRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.registerSuccess),
        tap(() => this.router.navigateByUrl('/thank-you')),
      ),
    { dispatch: false },
  );

  createAccount$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.createAccount),
        switchMap(({ payload }) =>
          this.authService.register(payload).pipe(
            map(({ response: success, uuid }) => {
              if (success) {
                //authActions.registerSuccess({ uuid }); // add address to user
              } else {
                //some fail
                // authActions.registerFail({
                //   error: new Error('Registration failed'),
                // });
              }
            }),
            catchError((error) => of(authActions.registerFail({ error }))),
          ),
        ),
      ),
    { dispatch: false },
  );

  recover$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.recoverPassword),
      switchMap(({ payload }) =>
        this.authService.recover(payload).pipe(
          map(() => authActions.recoverPasswordSuccess()),
          catchError((error) => of(authActions.recoverPasswordFail({ error }))),
        ),
      ),
    ),
  );

  recoverSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.recoverPasswordSuccess),
        tap(() =>
          this.toasterService.show(
            'Your password reset request was received. Please check your email for the next steps.',
            'success',
          ),
        ),
      ),
    { dispatch: false },
  );
  recoverPasswordToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.recoverPasswordFail),
        tap((error) => this.toasterService.show(error.error, 'error')),
      ),
    { dispatch: false },
  );

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.resetPassword),
      switchMap(({ payload }) =>
        this.authService.reset(payload).pipe(
          tap(() => {
            //this.authFacade.logout('login');
          }),
          map(() => authActions.resetPasswordSuccess()),
          catchError((error) => {
            this.toasterService.showToAccount(
              'Failed to update password',
              'error',
            );
            return of();
          }),
        ),
      ),
    ),
  );

  resetSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.resetPasswordSuccess),
        tap(() =>
          this.toasterService.showToAccount(
            'Your password has been updated.',
            'success',
          ),
        ),
      ),
    { dispatch: false },
  );

  generateLink$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.generateLink),
      switchMap(({ payload }) =>
        this.authService.generateLink(payload).pipe(
          map(() => authActions.generateLinkSuccess()),
          catchError((error) => of(authActions.generateLinkFail({ error }))),
        ),
      ),
    ),
  );

  generateLinkSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.generateLinkSuccess),
        tap(() =>
          this.toasterService.show(
            'Your request was received. Please check your email for the next steps.',
            'success',
          ),
        ),
      ),
    { dispatch: false },
  );

  generateLinkToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.generateLinkFail),
        tap((error) =>
          this.toasterService.show(
            "Oops! It seems like the email address you provided couldn't be found in our system",
            'error',
          ),
        ),
      ),
    { dispatch: false },
  );

  loginByLink$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.loginByLink),
      tap((user: any) => {
        this.loginUser(user.payload);
      }),
      map((user) =>
        authActions.loginSuccess({
          user: user.payload,
          redirectRoute: user.redirectRoute,
        }),
      ),
      catchError((error) => of(authActions.loginFail({ error }))),
    ),
  );

  generateOtp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.generateOtp),
      switchMap(({ payload }) =>
        this.authService.generateOtp(payload).pipe(
          map((data) => authActions.generateOtpSuccess({ data })),
          catchError((error) => of(authActions.generateOtpFail({ error }))),
        ),
      ),
    ),
  );

  generateOtpSuccessToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.generateOtpSuccess),
        tap((data) => {
          if (!data) {
            this.toasterService.show(
              'Something went wrong. Please try another login option',
              'error',
            );
          } else
            this.toasterService.show(
              'Your request was received. The code was sent to you by email/SMS.',
              'success',
            );
        }),
      ),
    { dispatch: false },
  );

  generateOtpToast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.generateOtpFail),
        tap((error) => this.toasterService.show(error.error, 'error')),
      ),
    { dispatch: false },
  );

  loginByCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.loginByCode),
      switchMap(({ payload }) =>
        this.authService.loginByCode(payload).pipe(
          tap((user) => {
            this.loginUser(user);
          }),
          map((user) => authActions.loginSuccess({ user })),
          catchError((error) => of(authActions.loginFail({ error }))),
        ),
      ),
    ),
  );

  // loginRegionRedirect$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(authActions.loginRegionRedirect),
  //     tap((user: any) => {
  //       this.loginUser(user.payload);
  //     }),
  //     map((user) => authActions.loginSuccess({ user: user.payload })),
  //     catchError((error) => of(authActions.loginFail({ error }))),
  //   ),
  // );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private authFacade: AuthFacade,
    private storageService: LocalStorageService,
    private toasterService: ToastService,
    private router: Router,
    private intercomService: IntercomService,
    private readonly facade: CartFacade,
  ) {}

  loginUser(user: IUser & { uuid: string }) {
    let cartId = this.storageService.getItem('cartId');
    if (cartId && cartId.length > 0) {
      this.facade.addItemsToUserCart(cartId, user.customId);
      this.storageService.setItem('cartId', '');
    }
    this.storageService.setItem('customId', user.customId);
    this.storageService.setItem('uuid', user.uuid);
    this.storageService.setItem('bearerToken', user.bearerToken);
    this.intercomService.setIntercomUser(user.customId, user.uuid);
    this.intercomService.customId = user.customId;
    this.intercomService.uuid = user.uuid;
  }
}
